import React from "react"
import { graphql } from "gatsby"
import { useIntl, FormattedMessage } from "react-intl"
import SEO from "components/seo"
import Layout from "components/layout"
import { Media } from "components/helpers"

import "scss/case-studies.scss"
import { localizedPath } from "../utils/helpers"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useLocalization } from "gatsby-theme-i18n"

const HookSEO = () => {
  const { formatMessage } = useIntl()

  const metadata = {
    title: formatMessage({ id: "Case studies" }),
    description: formatMessage({ id: "Some customer case studies" }),
    path: "/clients",
  }

  return <SEO {...metadata} />
}

const CaseStudies = ({ data: { allNodeRich } }) => {
  const { locale, defaultLang } = useLocalization()

  return (
    <Layout>
      <HookSEO />
      <div className="header-blue">
        <div className="listing-case-studies">
          <div className="container">
            <h1
              className="title--wavy"
              data-sal-duration="1500"
              data-sal="zoom-in"
            >
              <FormattedMessage id="Case studies" />
            </h1>
            <div
              className="listing__description"
              data-sal-duration="1500"
              data-sal="zoom-in"
            >
              <FormattedMessage id="Some customer case studies" />
            </div>
            {allNodeRich.edges.map(({ node }) => (
              <div className="cases__header" key={node.id}>
                <div
                  className="cases__subheader"
                  data-sal-duration="1500"
                  data-sal="slide-left"
                >
                  <div className="cases__title">{node.title}</div>
                  {node.relationships.field_paragraphs.map(
                    ({ id, internal, field_subtitle }) => {
                      if (!internal) return null
                      switch (internal.type) {
                        case "paragraph__header":
                          return (
                            <div className="case__subtitle" key={id}>
                              {field_subtitle}
                            </div>
                          )

                        default:
                          return null
                      }
                    }
                  )}
                  <div className="case__details">
                    {node.field_description_listing}
                  </div>
                  <AniLink
                    className="link case__link"
                    cover
                    bg="#e0f3f8"
                    direction="right"
                    duration={1}
                    to={localizedPath(defaultLang, locale, node.path.alias)}
                  >
                    <FormattedMessage id="seeProject" />
                  </AniLink>
                </div>
                <div
                  className="case__thumb"
                  data-sal-duration="1500"
                  data-sal="slide-right"
                >
                  <Media
                    field_image_media={node.relationships.field_image_listing}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudies

export const query = graphql`query CaseStudiesQuery($locale: String = "fr") {
  allNodeRich(
    sort: {fields: field_ordre}
    filter: {langcode: {eq: $locale}, field_page_type: {eq: "clients"}}
  ) {
    edges {
      node {
        id
        path {
          alias
        }
        title
        field_description_listing
        relationships {
          field_image_listing {
            field_media_image {
              alt
            }
            relationships {
              field_media_image {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(width: 478, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          field_paragraphs {
            ... on paragraph__header {
              ...HeaderParagraph
            }
          }
        }
      }
    }
  }
}
`
